import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    txIsAccepted,
    txIsCanceled,
    txIsDeclined,
    txIsEnquired,
    txIsPaymentExpired,
    txIsPaymentPending,
    txIsRequested,
    txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
    ensureListing,
    ensureTransaction,
    ensureUser,
    userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import { sendSGMail, updateMetadata } from '../../util/api';
import {
    AvatarLarge,
    BookingPanel,
    Button,
    NamedLink,
    ReviewModal,
    UserDisplayName,
    Modal,
    FieldTextInput,
    Form,
    FieldSelect,
    IconClose,
    IconEdit,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import { Form as FinalForm } from 'react-final-form'

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
    HEADING_ENQUIRED,
    HEADING_PAYMENT_PENDING,
    HEADING_PAYMENT_EXPIRED,
    HEADING_REQUESTED,
    HEADING_ACCEPTED,
    HEADING_DECLINED,
    HEADING_CANCELED,
    HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.module.css';
import IconReturn from './IconReturn';
import { getIntlTypeOfChargeOptionLabel, TYPE_OF_CHARGE_OPTIONS } from "../../data/typeOfCharges"
import { CLUSTER_SECTION } from '../../forms/EditListingClusterForm/ClusterForm';
import FieldCheckboxComponent from '../FieldCheckbox/FieldCheckbox';


// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
    const authorDisplayName = (
        <UserDisplayName user={currentProvider} intl={intl} />
    );
    const customerDisplayName = (
        <UserDisplayName user={currentCustomer} intl={intl} />
    );

    let otherUserDisplayName = '';
    let otherUserDisplayNameString = '';
    const currentUserIsCustomer =
        currentUser.id &&
        currentCustomer.id &&
        currentUser.id.uuid === currentCustomer.id.uuid;
    const currentUserIsProvider =
        currentUser.id &&
        currentProvider.id &&
        currentUser.id.uuid === currentProvider.id.uuid;

    if (currentUserIsCustomer) {
        otherUserDisplayName = authorDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(
            currentProvider,
            ''
        );
    } else if (currentUserIsProvider) {
        otherUserDisplayName = customerDisplayName;
        otherUserDisplayNameString = userDisplayNameAsString(
            currentCustomer,
            ''
        );
    }

    return {
        authorDisplayName,
        customerDisplayName,
        otherUserDisplayName,
        otherUserDisplayNameString,
    };
};

export class TransactionPanelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendMessageFormFocused: false,
            isReviewModalOpen: false,
            reviewSubmitted: false,
            isModalOpen: false,
            isEditMode: false,
            isDeleteOpen: false,
            formValues: {},
        };
        this.isMobSaf = false;
        this.sendMessageFormName = 'TransactionPanel.SendMessageForm';
        this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
        this.onSubmitReview = this.onSubmitReview.bind(this);
        this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
        this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
        this.onMessageSubmit = this.onMessageSubmit.bind(this);
        this.scrollToMessage = this.scrollToMessage.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onDeleteModal = this.onDeleteModal.bind(this);
    }

    componentDidMount() {
        this.isMobSaf = isMobileSafari();
    }

    onOpenReviewModal() {
        this.setState({ isReviewModalOpen: true });
    }

    onOpenModal(state) {
        this.setState({ isModalOpen: state });
    }
    onDeleteModal(state) {
        this.setState({ isDeleteOpen: state });
    }
    onSubmitReview(values) {
        const { onSendReview, transaction, transactionRole } = this.props;
        const currentTransaction = ensureTransaction(transaction);
        const { reviewRating, reviewContent } = values;
        const rating = Number.parseInt(reviewRating, 10);
        onSendReview(transactionRole, currentTransaction, rating, reviewContent)
            .then((r) =>
                this.setState({
                    isReviewModalOpen: false,
                    reviewSubmitted: true,
                })
            )
            .catch((e) => {
                // Do nothing.
            });
    }

    onSendMessageFormFocus() {
        this.setState({ sendMessageFormFocused: true });
        if (this.isMobSaf) {
            // Scroll to bottom
            window.scroll({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    onSendMessageFormBlur() {
        this.setState({ sendMessageFormFocused: false });
    }

    onMessageSubmit(values, form, reload) {
        const message = values.message ? values.message.trim() : null;
        const { transaction, onSendMessage } = this.props;
        const ensuredTransaction = ensureTransaction(transaction);

        if (!message) {
            return;
        }
        onSendMessage(ensuredTransaction.id, message)
            .then((messageId) => {
                if (reload) {
                    if (typeof window !== "undefined") { window.location.reload() }
                }
                form.reset();
                this.scrollToMessage(messageId);
            })
            .catch((e) => {
                // Ignore, Redux handles the error
            });
    }

    scrollToMessage(messageId) {
        const selector = `#msg-${messageId.uuid}`;
        const el = document.querySelector(selector);
        if (el) {
            el.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }

    render() {
        const {
            rootClassName,
            className,
            currentUser,
            transaction,
            totalMessagePages,
            oldestMessagePageFetched,
            messages,
            initialMessageFailed,
            savePaymentMethodFailed,
            fetchMessagesInProgress,
            fetchMessagesError,
            sendMessageInProgress,
            sendMessageError,
            sendReviewInProgress,
            sendReviewError,
            onFetchTimeSlots,
            onManageDisableScrolling,
            onShowMoreMessages,
            transactionRole,
            intl,
            onAcceptSale,
            onDeclineSale,
            acceptInProgress,
            declineInProgress,
            acceptSaleError,
            declineSaleError,
            onSubmitBookingRequest,
            monthlyTimeSlots,
            nextTransitions,
            onFetchTransactionLineItems,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            specialAddOns
        } = this.props;

        const currentTransaction = ensureTransaction(transaction);
        const currentListing = ensureListing(currentTransaction.listing);
        const currentProvider = ensureUser(currentTransaction.provider);
        const currentCustomer = ensureUser(currentTransaction.customer);
        const isCustomer = transactionRole === 'customer';
        const isProvider = transactionRole === 'provider';
        const savedBookingData = currentTransaction.attributes.metadata.rawBookingData || {};

        const listingLoaded = !!currentListing.id;
        const listingDeleted =
            listingLoaded && currentListing.attributes.deleted;
        const iscustomerLoaded = !!currentCustomer.id;
        const isCustomerBanned =
            iscustomerLoaded && currentCustomer.attributes.banned;
        const isCustomerDeleted =
            iscustomerLoaded && currentCustomer.attributes.deleted;
        const isProviderLoaded = !!currentProvider.id;
        const isProviderBanned =
            isProviderLoaded && currentProvider.attributes.banned;
        const isProviderDeleted =
            isProviderLoaded && currentProvider.attributes.deleted;

        const stateDataFn = (tx) => {
            if (txIsEnquired(tx)) {
                const transitions = Array.isArray(nextTransitions)
                    ? nextTransitions.map((transition) => {
                        return transition.attributes.name;
                    })
                    : [];
                const hasCorrectNextTransition =
                    transitions.length > 0 &&
                    transitions.includes(
                        TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
                    );
                return {
                    headingState: HEADING_ENQUIRED,
                    showBookingPanel:
                        isCustomer &&
                        !isProviderBanned &&
                        hasCorrectNextTransition,
                };
            } else if (txIsPaymentPending(tx)) {
                return {
                    headingState: HEADING_PAYMENT_PENDING,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsPaymentExpired(tx)) {
                return {
                    headingState: HEADING_PAYMENT_EXPIRED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsRequested(tx)) {
                return {
                    headingState: HEADING_REQUESTED,
                    showDetailCardHeadings: isCustomer,
                    showSaleButtons: isProvider && !isCustomerBanned,
                    showCancelButton: !isProvider,
                };
            } else if (txIsAccepted(tx)) {
                return {
                    headingState: HEADING_ACCEPTED,
                    showDetailCardHeadings: true,
                    showAddress: true,
                };
            } else if (txIsDeclined(tx)) {
                return {
                    headingState: HEADING_DECLINED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txIsCanceled(tx)) {
                return {
                    headingState: HEADING_CANCELED,
                    showDetailCardHeadings: isCustomer,
                };
            } else if (txHasBeenDelivered(tx)) {
                return {
                    headingState: HEADING_DELIVERED,
                    showDetailCardHeadings: true,
                    showAddress: true,
                };
            } else {
                return { headingState: 'unknown' };
            }
        };
        const stateData = stateDataFn(currentTransaction);
        const isRequestedTx = stateData?.headingState === HEADING_REQUESTED;

        const deletedListingTitle = intl.formatMessage({
            id: 'TransactionPanel.deletedListingTitle',
        });

        const {
            authorDisplayName,
            customerDisplayName,
            otherUserDisplayName,
            otherUserDisplayNameString,
        } = displayNames(currentUser, currentProvider, currentCustomer, intl);

        const { publicData, geolocation } = currentListing.attributes;
        const phoneNumber = isProvider ? currentCustomer?.attributes?.profile?.publicData?.phoneNumber : currentProvider?.attributes?.profile?.publicData?.phoneNumber
        const company = isProvider ? currentCustomer?.attributes?.profile?.publicData?.company : ''
        const location =
            publicData && publicData.location ? publicData.location : {};
        const listingTitle = currentListing.attributes.deleted
            ? deletedListingTitle
            : currentListing.attributes.title;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;

        const unitTranslationKey = isNightly
            ? 'TransactionPanel.perNight'
            : isDaily
                ? 'TransactionPanel.perDay'
                : 'TransactionPanel.perUnit';

        const price = currentListing.attributes.price;
        const bookingSubTitle = price
            ? `${formatMoney(intl, price, 1.1)} ${intl.formatMessage({
                id: unitTranslationKey,
            })}`
            : '';

        const firstImage =
            currentListing.images && currentListing.images.length > 0
                ? currentListing.images[0]
                : null;

        const submitG4 = (type) => {
            const personLineItem =
                currentTransaction?.attributes.lineItems.find((line) =>
                    line.code.includes('per person')
                );
            const quantity = personLineItem ? personLineItem.quantity.d[0] : '';
            const findAddOns = currentTransaction?.attributes.lineItems.filter(
                (line) => line.code.includes('/addOn:')
            );
            const addOns = findAddOns.map((line) => {
                return line.code.substring(17);
            });
            const formattedTotalPrice = formatMoney(
                intl,
                currentTransaction?.attributes?.payoutTotal
            ).substring(4);

            window.dataLayer.push({
                event:
                    type == 'accept' ? 'booking_accepted' : stateData.showSaleButtons ? 'booking_declined' : 'booking_cancelled',
                booking_id: currentTransaction?.booking?.id.uuid,
                transaction_id: currentTransaction?.id.uuid,
                space_id: currentTransaction?.listing?.id.uuid,
                start_date:
                    currentTransaction?.booking?.attributes?.displayStart?.toLocaleDateString(
                        'en-GB'
                    ),
                end_date:
                    currentTransaction?.booking?.attributes?.displayEnd?.toLocaleDateString(
                        'en-GB'
                    ),
                currency: currentTransaction?.attributes?.payinTotal?.currency,
                price: currentTransaction?.attributes?.payinTotal?.amount / 100,
                spaces: currentListing?.attributes?.title,
                // activities: selectedActivity?.title,
                no_of_guest: quantity,
                add_ons: addOns,
                host_id: currentTransaction?.listing?.author?.id?.uuid,
                host_name:
                    currentTransaction?.listing?.author?.attributes?.profile
                        ?.displayName,
            });

            if (type == "accept") {
                onAcceptSale(currentTransaction.id)
            } else {
                onDeclineSale(
                    currentTransaction.id,
                    stateData.showCancelButton
                )
            }
        }

        const saleButtons = (
            <SaleActionButtonsMaybe
                showButtons={
                    stateData.showSaleButtons || stateData.showCancelButton
                }
                acceptInProgress={acceptInProgress}
                declineInProgress={declineInProgress}
                acceptSaleError={acceptSaleError}
                declineSaleError={declineSaleError}
                showCancelButton={stateData.showCancelButton}
                onAcceptSale={() => submitG4('accept')}
                onDeclineSale={() => {
                    if (
                        window.confirm(
                            `Confirm ${stateData.showSaleButtons ? 'decline' : 'cancel'
                            } this booking?`
                        )
                    ) {
                        submitG4('decline');
                    }
                }}
            />
        );

        const showSendMessageForm =
            !isCustomerBanned &&
            !isCustomerDeleted &&
            !isProviderBanned &&
            !isProviderDeleted;

        const sendMessagePlaceholder = intl.formatMessage(
            { id: 'TransactionPanel.sendMessagePlaceholder' },
            { name: otherUserDisplayNameString }
        );

        const sendingMessageNotAllowed = intl.formatMessage({
            id: 'TransactionPanel.sendingMessageNotAllowed',
        });

        const paymentMethodsPageLink = (
            <NamedLink name="PaymentMethodsPage">
                <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
            </NamedLink>
        );
        const classes = classNames(rootClassName || css.root, className);
        const titleInputLabel = intl.formatMessage({
            id: 'EditListingAddOnForm.title',
        });
        const titlePlaceholderMessage = intl.formatMessage({
            id: 'TransactionPanelAddOnModal.titleInputPlaceholder',
        });

        const descriptionInputLabel = intl.formatMessage({
            id: 'EditListingAddOnForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
            id: 'TransactionPanelAddOnModal.descriptionInputPlaceholder',
        });
        const typeOfChargeInputLabel = intl.formatMessage({
            id: 'EditListingAddOnForm.typeOfCharge',
        });
        const typeOfChargePlaceholderMessage = intl.formatMessage({
            id: 'EditListingAddOnForm.typeOfChargeInputPlaceholder',
        });
        const translationKey = isNightly
            ? 'EditListingAddOnForm.pricePerNight'
            : isDaily
                ? 'EditListingAddOnForm.pricePerDay'
                : 'EditListingAddOnForm.pricePerUnit';
        const pricePerUnitMessage = intl.formatMessage({
            id: translationKey,
        });
        const pricePlaceholderMessage = intl.formatMessage({
            id: 'TransactionPanelAddOnModal.priceInputPlaceholder',
        });

        const checkboxMessage = intl.formatMessage({
            id: 'TransactionPanelAddOnModal.removeBookingFeeCheckbox',
        });
        const addOnTitle = this.props.transaction.attributes.metadata.specialAddOns ? this.props.transaction.attributes.metadata.specialAddOns.map(i => i.title).join(', ') : null;

        const rawBookingData = transaction.attributes.metadata.rawBookingData;
        const listingAddons = transaction.listing.attributes.publicData.addOns;
        const listingClusters = transaction.listing.attributes.publicData.clusters;

        return (
            <div className={classes}>
                <div className={css.returnToInbox}>
                    <NamedLink name="InboxBasePage">
                        <IconReturn className={css.icon} />
                    </NamedLink>
                </div>

                <div className={css.container}>
                    <div className={css.txInfo}>
                        <DetailCardImage
                            rootClassName={css.imageWrapperMobile}
                            avatarWrapperClassName={css.avatarWrapperMobile}
                            listingTitle={listingTitle}
                            image={firstImage}
                            provider={currentProvider}
                            isCustomer={isCustomer}
                            listingId={
                                currentListing.id && currentListing.id.uuid
                            }
                            listingDeleted={listingDeleted}
                        />

                        {isProvider ? (
                            <div className={css.avatarWrapperProviderDesktop}>
                                <AvatarLarge
                                    user={currentCustomer}
                                    className={css.avatarDesktop}
                                />
                                <div><div style={{ fontWeight: "500", marginTop: "15px" }}>{customerDisplayName}</div>
                                    <div>{company}</div>
                                </div>


                            </div>
                        ) : null}

                        <PanelHeading
                            isRequestedTx={isRequestedTx}
                            panelHeadingState={stateData.headingState}
                            transactionRole={transactionRole}
                            providerName={authorDisplayName}
                            customerName={customerDisplayName}
                            isCustomerBanned={isCustomerBanned}
                            listingId={
                                currentListing.id && currentListing.id.uuid
                            }
                            listingTitle={listingTitle}
                            listingDeleted={listingDeleted}
                        />


                        <div className={css.bookingDetailsMobile}>
                            <AddressLinkMaybe
                                rootClassName={css.addressMobile}
                                location={location}
                                geolocation={geolocation}
                                showAddress={stateData.showAddress}
                                phoneNumber={phoneNumber}
                            />

                            <BreakdownMaybe
                                transaction={currentTransaction}
                                transactionRole={transactionRole}
                            />
                        </div>
                        {addOnTitle && isCustomer && transaction.attributes.lastTransition == 'transition/enquire' ? <>

                            <p style={{ textAlign: "center", color: "#30a64a" }}>{otherUserDisplayNameString} has created an individual offer for you. You can select it during the booking process as an add-on.</p>
                        </> : null}
                        {addOnTitle && isProvider && transaction.attributes.lastTransition == 'transition/enquire' ? <>

                            <p style={{ textAlign: "center", color: "#30a64a" }}>You've added an individual offer</p>
                        </> : null}

                        {savePaymentMethodFailed ? (
                            <p className={css.genericError}>
                                <FormattedMessage
                                    id="TransactionPanel.savePaymentMethodFailed"
                                    values={{ paymentMethodsPageLink }}
                                />
                            </p>
                        ) : null}
                        <FeedSection
                            rootClassName={css.feedContainer}
                            currentTransaction={currentTransaction}
                            currentUser={currentUser}
                            fetchMessagesError={fetchMessagesError}
                            fetchMessagesInProgress={fetchMessagesInProgress}
                            initialMessageFailed={initialMessageFailed}
                            messages={messages}
                            oldestMessagePageFetched={oldestMessagePageFetched}
                            onOpenReviewModal={this.onOpenReviewModal}
                            onShowMoreMessages={() =>
                                onShowMoreMessages(currentTransaction.id)
                            }
                            totalMessagePages={totalMessagePages}
                        />
                        {showSendMessageForm ? (
                            <div className={css.sendMessage}>
                                <SendMessageForm
                                    formId={this.sendMessageFormName}
                                    rootClassName={css.sendMessageForm}
                                    messagePlaceholder={sendMessagePlaceholder}
                                    inProgress={sendMessageInProgress}
                                    sendMessageError={sendMessageError}
                                    onFocus={this.onSendMessageFormFocus}
                                    onBlur={this.onSendMessageFormBlur}
                                    onSubmit={this.onMessageSubmit}
                                    intl={intl}
                                />
                            </div>
                        ) : (
                            <div className={css.sendingMessageNotAllowed}>
                                {sendingMessageNotAllowed}
                            </div>
                        )}

                        {stateData.showSaleButtons ||
                            stateData.showCancelButton ? (
                            <div className={css.mobileActionButtons}>
                                {saleButtons}
                            </div>

                        ) : null}
                    </div>


                    <div className={css.asideDesktop}>
                        <div className={css.detailCard}>
                            <DetailCardImage
                                avatarWrapperClassName={
                                    css.avatarWrapperDesktop
                                }
                                listingTitle={listingTitle}
                                image={firstImage}
                                provider={currentProvider}
                                isCustomer={isCustomer}
                                listingId={
                                    currentListing.id && currentListing.id.uuid
                                }
                                listingDeleted={listingDeleted}
                            />


                            <DetailCardHeadingsMaybe
                                showDetailCardHeadings={
                                    stateData.showDetailCardHeadings
                                }
                                listingTitle={listingTitle}
                                subTitle={bookingSubTitle}
                                location={location}
                                geolocation={geolocation}
                                showAddress={stateData.showAddress}
                                phoneNumber={phoneNumber}
                                isProvider={isProvider}
                            />

                            {stateData.showBookingPanel ? (
                                <BookingPanel
                                    className={css.bookingPanel}
                                    titleClassName={css.bookingTitle}
                                    isOwnListing={false}
                                    listing={currentListing}
                                    title={listingTitle}
                                    subTitle={bookingSubTitle}
                                    authorDisplayName={authorDisplayName}
                                    onSubmit={onSubmitBookingRequest}
                                    savedBookingData={savedBookingData}
                                    onManageDisableScrolling={
                                        onManageDisableScrolling
                                    }
                                    monthlyTimeSlots={monthlyTimeSlots}
                                    onFetchTimeSlots={onFetchTimeSlots}
                                    onFetchTransactionLineItems={
                                        onFetchTransactionLineItems
                                    }
                                    lineItems={lineItems}
                                    fetchLineItemsInProgress={
                                        fetchLineItemsInProgress
                                    }
                                    onMessageSubmit={this.onMessageSubmit}
                                    fetchLineItemsError={fetchLineItemsError}
                                    specialAddOns={currentTransaction?.attributes?.metadata?.specialAddOns}
                                />
                            ) : null}

                            <BreakdownMaybe
                                className={css.breakdownContainer}
                                transaction={currentTransaction}
                                transactionRole={transactionRole}
                            />

                            {stateData.showSaleButtons ||
                                stateData.showCancelButton ? (
                                <div className={css.desktopActionButtons}>
                                    {saleButtons}
                                </div>
                            ) : null}
                        </div>
                        <p></p>

                        <Modal
                            id="customAddOnsModal"
                            isOpen={this.state.isModalOpen}
                            onClose={() =>
                                this.setState({ isModalOpen: false })
                            }
                            onManageDisableScrolling={onManageDisableScrolling}
                            containerClassName={css.modalContainer}
                            usePortal>
                            <FinalForm
                                onSubmit={() => { }}
                                initialValues={this.state.formValues}
                                render={(formRenderProps) => {

                                    const {
                                        disabled,
                                        ready,
                                        invalid,
                                        pristine,
                                        updated,
                                        updateInProgress,
                                        values,
                                        form,
                                    } = formRenderProps;

                                    const submitInProgress = updateInProgress;
                                    const subfieldsSubmitDisabled =
                                        invalid || disabled || submitInProgress;
                                    const submitDisabled = subfieldsSubmitDisabled;
                                    const submitReady = !values?.newEdit
                                        ? (!submitDisabled && updated && pristine) ||
                                        (!submitDisabled && ready)
                                        : false;

                                    return <>
                                        <Form>
                                            <FieldTextInput
                                                type="text"
                                                id="customAddonTitle"
                                                name="title"
                                                className={css.title}
                                                label={titleInputLabel}
                                                placeholder={titlePlaceholderMessage}
                                            />
                                            <FieldTextInput
                                                type="textarea"
                                                id="customAddonDescription"
                                                name="description"
                                                label={descriptionInputLabel}
                                                placeholder={descriptionPlaceholderMessage}
                                                className={css.title}
                                            />

                                            <FieldSelect
                                                id="typeOfCharge"
                                                name="typeOfCharge"
                                                className={css.typeOfCharge}
                                                label={typeOfChargeInputLabel}
                                            >
                                                <option disabled value="">
                                                    {typeOfChargePlaceholderMessage}
                                                </option>
                                                {TYPE_OF_CHARGE_OPTIONS.map((c) => (
                                                    <option key={c} value={c}>
                                                        {getIntlTypeOfChargeOptionLabel(c, intl)}
                                                    </option>
                                                ))}
                                            </FieldSelect>

                                            <FieldTextInput
                                                id="price"
                                                name="price"
                                                type="number"
                                                className={css.priceInput}
                                                label={pricePerUnitMessage}
                                                placeholder={pricePlaceholderMessage}
                                            />


                                            <div className={css.removeFeeCheckbox}>
                                                <FieldCheckboxComponent
                                                    id="removeFee"
                                                    name="removeFee"
                                                    label={checkboxMessage}
                                                    textClassName={css.checkboxLabel}
                                                />
                                            </div>

                                            <div className={css.earningsContainer}>
                                                <p className={css.earnings}><FormattedMessage id='EditListingAddOnForm.earnings' /> {config.currencyConfig.currency}{values?.price ? (values?.price * (values.removeFee ? 0.925 : 0.95)).toFixed(2) : "N/A"}</p>
                                                <p className={css.earnings}><FormattedMessage id='EditListingAddOnForm.listingPrice' /> {config.currencyConfig.currency}{values?.price ? (values?.price * (values.removeFee ? 1.075 : 1.05)).toFixed(2) : "N/A"}</p>
                                            </div>
                                            <Button
                                                className={classNames(
                                                    css.submitButton,
                                                    css.subFieldsSubmitBtn
                                                )}
                                                type="button"
                                                inProgress={submitInProgress}
                                                disabled={!values.price || !values.title || !values.description || !values.typeOfCharge}
                                                ready={submitReady}
                                                onClick={() => {
                                                    const isEdit = window.localStorage.getItem('isEditAddon');
                                                    window.localStorage.removeItem('isEditAddon');
                                                    const updatedValue = {
                                                        addOn: {
                                                            description: values.description,
                                                            price: {
                                                                amount: values.price * 100,
                                                                currency: config.currencyConfig.currency,
                                                            },
                                                            title: values.title,
                                                            typeOfCharge: values.typeOfCharge,
                                                            removeFee: values.removeFee,
                                                            // uuid: values.uuid,
                                                            // isNewAddOn: isEdit == 'yes' ? false : true,
                                                        }
                                                    };
                                                    if (values?.isClusterDraft) {
                                                        updatedValue.cluster = {
                                                            draftCluster: {
                                                                uuid: values?.isClusterDraft,
                                                                nextSection:
                                                                    CLUSTER_SECTION.ADD_ON,
                                                            },
                                                        };
                                                    };
                                                    const oldAddOns = this.props.transaction.attributes.metadata.specialAddOns;
                                                    const stringUUID = (Math.random() + 1).toString(36).substring(2);
                                                    let theMetaParams = {}

                                                    if (this.state.isEditMode) {
                                                        theMetaParams = {
                                                            specialAddOns: oldAddOns.map(e => {
                                                                if (e.uuid !== this.state.isEditMode) {
                                                                    return e
                                                                } else {
                                                                    return { ...updatedValue.addOn, uuid: this.state.isEditMode }
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        theMetaParams = oldAddOns ?
                                                            { specialAddOns: [...oldAddOns, { ...updatedValue.addOn, uuid: stringUUID }] } :
                                                            { specialAddOns: [{ ...updatedValue.addOn, uuid: stringUUID }] };
                                                    }

                                                    updateMetadata({
                                                        id: this.props.transaction.id.uuid,

                                                        metaParams: theMetaParams,
                                                    }).then(res => {
                                                        this.onOpenModal(false);
                                                        const object = {
                                                            message: `A new offer (${values.title}) has been added, you have 48 hours to accept the offer.`
                                                        }
                                                        const totalPriceCustomer = values.price * (values.removeFee ? 1.075 : 1.05)
                                                        const vatAmountCustomer = totalPriceCustomer * 0.081
                                                        const subTotalPriceCustomer = totalPriceCustomer - vatAmountCustomer

                                                        const totalPriceProvider = values.price * (values.removeFee ? 0.925 : 0.95)
                                                        const vatAmountProvider = totalPriceProvider * 0.081
                                                        const subTotalPriceProvider = totalPriceProvider - vatAmountProvider

                                                        // Send notification to Customer on Custom offer
                                                        sendSGMail({
                                                            userId: currentCustomer.id.uuid,
                                                            subject: `Robin Room: A new offer has been added to your request`,
                                                            template: "new-custom-offer-customer",
                                                            emailParams: {
                                                                name: values.title,
                                                                description: values.description,
                                                                typeOfCharge: getIntlTypeOfChargeOptionLabel(values.typeOfCharge, intl),
                                                                total: `${config.currencyConfig.currency} ${totalPriceCustomer.toFixed(2)}`,
                                                                subtotal: `${config.currencyConfig.currency} ${subTotalPriceCustomer.toFixed(2)}`,
                                                                vatAmount: `${config.currencyConfig.currency} ${vatAmountCustomer.toFixed(2)}`,
                                                                listingTitle: currentListing?.attributes?.title,
                                                                transactionId: currentTransaction?.id?.uuid,
                                                                providerName: currentProvider.attributes.profile.displayName,
                                                                customerName: currentCustomer.attributes.profile.displayName,
                                                            },
                                                            text: `A new offer (${values.title}) has been added, you have 48 hours to accept the offer.`,
                                                        })

                                                        // // Send notification to Provider on Custom offer
                                                        sendSGMail({
                                                            userId: currentProvider.id.uuid,
                                                            subject: `Robin Room: A new offer has been created`,
                                                            template: "new-custom-offer-host",
                                                            emailParams: {
                                                                name: values.title,
                                                                description: values.description,
                                                                typeOfCharge: getIntlTypeOfChargeOptionLabel(values.typeOfCharge, intl),
                                                                total: `${config.currencyConfig.currency} ${totalPriceProvider.toFixed(2)}`,
                                                                subtotal: `${config.currencyConfig.currency} ${subTotalPriceProvider.toFixed(2)}`,
                                                                vatAmount: `${config.currencyConfig.currency} ${vatAmountProvider.toFixed(2)}`,
                                                                listingTitle: currentListing?.attributes?.title,
                                                                transactionId: currentTransaction?.id?.uuid,
                                                                providerName: currentProvider.attributes.profile.displayName,
                                                                customerName: currentCustomer.attributes.profile.displayName,
                                                            },
                                                            text: `A new offer (${values.title}) has been added, you have 48 hours to accept the offer.`,
                                                        })
                                                        this.onMessageSubmit(object, null, true)
                                                    }).catch(err => console.log('err', err));
                                                }}>
                                                {this.state.isEditMode ? "Edit" : "Make an offer"}
                                            </Button>
                                        </Form>
                                    </>
                                }}
                            />
                        </Modal>
                        <div className={css.paddingWrapper}>

                            {rawBookingData && isProvider && transaction.attributes.lastTransition == 'transition/enquire' ? <div className={css.plansWrapper}>
                                <div className={css.EnquiryText}>
                                    Enquiry Details
                                </div>

                                <div className={css.columnsWrapper}>
                                    <div className={css.leftColumn}>
                                        <div>
                                            <div style={{ color: "var(--marketplaceColor)" }}>
                                                Planned Activity
                                            </div>
                                            {listingClusters.find(c => c.uuid == rawBookingData.activity)?.title}
                                        </div>

                                        {rawBookingData.bookingStartTime ? <div>
                                            <div style={{ color: "var(--marketplaceColor)" }}>
                                                Booking start
                                            </div>
                                            {new Date(Number(rawBookingData.bookingStartTime)).toLocaleString("en-GB")}
                                        </div> : null}

                                        {rawBookingData.addOns ?
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }}>
                                                <div style={{ color: "var(--marketplaceColor)" }}>
                                                    Add-Ons
                                                </div>
                                                {Object.keys(rawBookingData?.addOns).map((key, i) => {
                                                    return <span>
                                                        {listingAddons.find(a => a.uuid == key)?.title}
                                                        {i + 1 < Object.keys(rawBookingData?.addOns).length && ", "}
                                                        {/* ({rawBookingData?.addOns[key].qty ?
                                                            rawBookingData?.addOns[key].qty
                                                            : 1}) */}
                                                    </span>
                                                })}
                                            </div>
                                            : null
                                        }
                                    </div>

                                    <div className={css.rightColumn}>
                                        {rawBookingData.noOfGuest ? <div>
                                            <div style={{ color: "var(--marketplaceColor)" }}>
                                                Number of Guests
                                            </div>
                                            {rawBookingData.noOfGuest}
                                        </div> : null}

                                        {rawBookingData.bookingEndTime ? <div>
                                            <div style={{ color: "var(--marketplaceColor)" }}>
                                                Booking end
                                            </div>
                                            {new Date(Number(rawBookingData.bookingEndTime)).toLocaleString("en-GB")}
                                        </div> : null}

                                    </div>
                                </div>
                            </div> : null}
                        </div>

                        <div className={css.individualOfferText}>
                            {
                                isProvider && transaction.attributes.lastTransition == 'transition/enquire' ? <>
                                    <Button onClick={() => {
                                        this.setState({ isEditMode: false })
                                        this.setState({ formValues: {} })
                                        this.onOpenModal(true)
                                    }}

                                        className={css.offerWrapper}
                                    >Make an individual offer</Button>
                                    <p className={css.ifYourGuest}>If your guest doesn’t find the right offer from the pre-set add-ons, you can create an individual offer which can be only booked by the guest you are currently chatting with</p>
                                </> : null
                            }
                        </div>

                        {transaction.attributes.metadata.specialAddOns?.map(addon => {
                            return <> {addOnTitle && isProvider && transaction.attributes.lastTransition == 'transition/enquire' ?
                                <div className={css.individualOffer}>
                                    <div className={css.wrapper}>
                                        <div >• {addon.title}: {getIntlTypeOfChargeOptionLabel(addon.typeOfCharge, intl)} | {addon.price.amount / 100}{addon.price.currency}</div>
                                        <div className={css.buttonsWrapper} >
                                            <div className={css.editButton} onClick={() => {
                                                this.setState({
                                                    formValues: {
                                                        title: addon.title,
                                                        description: addon.description,
                                                        typeOfCharge: addon.typeOfCharge,
                                                        price: addon.price.amount / 100,
                                                        removeFee: addon.removeFee,
                                                    },
                                                    isEditMode: addon.uuid,
                                                })
                                                this.onOpenModal(true)
                                            }}><IconEdit /></div>
                                            <div className={css.deleteButton} onClick={() => {

                                                this.onDeleteModal(true)
                                            }}><IconClose /></div>
                                        </div>

                                        <Modal
                                            id="delete modal"
                                            isOpen={this.state.isDeleteOpen}
                                            onClose={() =>
                                                this.setState({ isDeleteOpen: false })
                                            }
                                            onManageDisableScrolling={onManageDisableScrolling}
                                            containerClassName={css.modalContainer}
                                            usePortal>

                                            <p>Do you want to remove this offer?</p>
                                            <div className={css.modalWrapper}>
                                                <Button className={css.deleteModal} onClick={() => {
                                                    updateMetadata({
                                                        id: this.props.transaction.id.uuid,
                                                        metaParams: {
                                                            specialAddOns: transaction.attributes.metadata.specialAddOns.filter(a => a.uuid !== addon.uuid)
                                                        },
                                                    }).then(res => {
                                                        if (typeof window !== "undefined") { window.location.reload() }
                                                    }).catch(err => console.log('err', err));
                                                }}>
                                                    Remove
                                                </Button>
                                                <Button className={css.cancelModal} onClick={() => {
                                                    this.onDeleteModal(false)
                                                }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                                : null} </>
                        })
                        }
                    </div>
                </div>

                <ReviewModal
                    id="ReviewOrderModal"
                    isOpen={this.state.isReviewModalOpen}
                    onCloseModal={() => this.setState({ isReviewModalOpen: false })}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onSubmitReview={this.onSubmitReview}
                    revieweeName={otherUserDisplayName}
                    reviewSent={this.state.reviewSubmitted}
                    sendReviewInProgress={sendReviewInProgress}
                    sendReviewError={sendReviewError}
                />
            </div>
        );
    }
}

TransactionPanelComponent.defaultProps = {
    rootClassName: null,
    className: null,
    currentUser: null,
    acceptSaleError: null,
    declineSaleError: null,
    fetchMessagesError: null,
    initialMessageFailed: false,
    savePaymentMethodFailed: false,
    sendMessageError: null,
    sendReviewError: null,
    monthlyTimeSlots: null,
    nextTransitions: null,
    lineItems: null,
    fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
    rootClassName: string,
    className: string,

    currentUser: propTypes.currentUser,
    transaction: propTypes.transaction.isRequired,
    totalMessagePages: number.isRequired,
    oldestMessagePageFetched: number.isRequired,
    messages: arrayOf(propTypes.message).isRequired,
    initialMessageFailed: bool,
    savePaymentMethodFailed: bool,
    fetchMessagesInProgress: bool.isRequired,
    fetchMessagesError: propTypes.error,
    sendMessageInProgress: bool.isRequired,
    sendMessageError: propTypes.error,
    sendReviewInProgress: bool.isRequired,
    sendReviewError: propTypes.error,
    onFetchTimeSlots: func.isRequired,
    onManageDisableScrolling: func.isRequired,
    onShowMoreMessages: func.isRequired,
    onSendMessage: func.isRequired,
    onSendReview: func.isRequired,
    onSubmitBookingRequest: func.isRequired,
    monthlyTimeSlots: object,
    nextTransitions: array,

    // Sale related props
    onAcceptSale: func.isRequired,
    onDeclineSale: func.isRequired,
    acceptInProgress: bool.isRequired,
    declineInProgress: bool.isRequired,
    acceptSaleError: propTypes.error,
    declineSaleError: propTypes.error,

    // line items
    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,

    // from injectIntl
    intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
