import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
    txIsCanceled,
    txIsDelivered,
    txIsDeclined,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingBreakdown.module.css';

const LineItemUnitPrice = (props) => {
    const { transaction, isProvider, intl } = props;

const { Money } = sdkTypes;


    let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
    if (txIsDelivered(transaction)) {
        providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
    } else if (txIsDeclined(transaction)) {
        providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
    } else if (txIsCanceled(transaction)) {
        providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
    }

    const totalLabel = isProvider ? (
        <FormattedMessage id={providerTotalMessageId} />
    ) : (
        <FormattedMessage id="BookingBreakdown.total" />
    );

    const totalPrice = isProvider
        ? transaction.attributes.payoutTotal
        : transaction.attributes.payinTotal;
    const formattedTotalPrice = formatMoney(intl, totalPrice);

    const totalAmount = totalPrice.amount
    const taxAmount = totalAmount * 0.081

    const formattedTaxAmount = formatMoney(intl, new Money(taxAmount, totalPrice.currency))

    return (
        <>
            <hr className={css.totalDivider} />
            <div className={css.lineItemTotal}>
                <div className={css.totalLabel}>{totalLabel}</div>
                <div className={css.totalPrice}>{formattedTotalPrice}</div>
            </div>
            <div className={css.subTotalLineItem}>
                <span className={css.itemLabel}>
                    <FormattedMessage id="BookingBreakdown.taxIncluded" />
                </span>
                <span className={css.itemValue}>{formattedTaxAmount}</span>
            </div>
        </>
    );
};

LineItemUnitPrice.propTypes = {
    transaction: propTypes.transaction.isRequired,
    isProvider: bool.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
