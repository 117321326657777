import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { timestampToDate } from '../../util/dates';
import config from '../../config';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import {
    FieldSelect,
    FieldTextInput,
    Form,
    IconSpinner,
    PrimaryButton,
    FieldCheckbox
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import * as validators from '../../util/validators';

import css from './BookingTimeForm.module.css';
import IconChat from '../../components/IconChat/IconChat';
import {
    formatMoney,
    unitDivisor,
    convertMoneyToNumber,
    convertUnitToSubUnit,
} from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TYPE_OF_CHARGE, CHARGE_DISPLAY, getIntlTypeOfChargeOptionLabel } from '../../data/typeOfCharges';
import Decimal from 'decimal.js';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import IconInfo from '../../components/IconInfo/IconInfo';
const { Money } = sdkTypes;

export class BookingTimeFormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentAddOn: [],
            isBookingRequest: false,
            alreadyOpen: false,
            removeFee: false,
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }


    handleFormSubmit(e) {
        this.props.onSubmit(e);
    }

    componentDidUpdate() {
        const urlParams = window.location.search;
        if (urlParams.includes('enquire=yes') && !this.state.alreadyOpen) {
            this.props.onContactUser();
            this.setState({ alreadyOpen: true });
        };
    }

    calculateQuantityFromHours = (startDate, endDate) => {
        return moment(endDate).diff(moment(startDate), 'hours', true);
    };

    // When the values of the form are updated we need to fetch
    // lineItems from FTW backend for the EstimatedTransactionMaybe
    // In case you add more fields to the form, make sure you add
    // the values here to the bookingData object.
    handleOnChange(formValues) {
        const {
            bookingStartTime,
            bookingEndTime,
            activity,
            addOns: vAddOns,
            noOfGuest,
        } = formValues.values;

        const startDate = bookingStartTime
            ? timestampToDate(bookingStartTime)
            : null;
        const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;



        const listingId = this.props.listingId;
        const isOwnListing = this.props.isOwnListing;

        // We expect values bookingStartTime and bookingEndTime to be strings
        // which is the default case when the value has been selected through the form
        const isSameTime = bookingStartTime === bookingEndTime;

        // formating addons
        const addOnKeys = vAddOns ? Object.keys(vAddOns) : []
        const addOns =
            vAddOns &&
            addOnKeys
                ?.filter((_k) => vAddOns[_k])
                .map((_k) => ({ ...vAddOns[_k], uuid: _k }));

        this.setState({ currentAddOn: addOns })
        const selectedActivity = this.props.activities.find(
            (_a) => _a.uuid === activity
        );
        const activityAddOnOptions = selectedActivity?.addOns || []

        const removeFee = activityAddOnOptions.filter(op => vAddOns && vAddOns[op.uuid] && op.removeFee)?.length > 0
        this.setState({ removeFee })
        if (
            bookingStartTime &&
            bookingEndTime &&
            activity &&
            noOfGuest &&
            !isSameTime &&
            !this.props.fetchLineItemsInProgress
        ) {
            this.props.onFetchTransactionLineItems({
                bookingData: {
                    startDate,
                    endDate,
                    noOfGuest,
                    addOns,
                    activity,
                    specialAddOns: this.props.specialAddOns,
                    removeFee,
                },
                listingId,
                isOwnListing,
            });
        }
    }
    render() {
        const {
            rootClassName,
            className,
            price: unitPrice,
            onContactUser,
            activities,
            onChangeFormValues,
            minHour,
            onUpdateProfile,
            currentUser,
            specialAddOns,
            transaction,
            isListingPagePanel,
            ...rest
        } = this.props;
        const classes = classNames(rootClassName || css.root, className);

        if (!unitPrice) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
                    </p>
                </div>
            );
        }

        if (unitPrice.currency !== config.currency) {
            return (
                <div className={classes}>
                    <p className={css.error}>
                        <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
                    </p>
                </div>
            );
        }

        return (
            <FinalForm
                {...rest}
                mutators={{ ...arrayMutators }}
                unitPrice={unitPrice}
                onSubmit={this.handleFormSubmit}
                render={(fieldRenderProps) => {
                    const {
                        endDatePlaceholder,
                        startDatePlaceholder,
                        form,
                        pristine,
                        handleSubmit,
                        intl,
                        isOwnListing,
                        listingId,
                        listing,
                        submitButtonWrapperClassName,
                        unitType,
                        values,
                        monthlyTimeSlots,
                        onFetchTimeSlots,
                        timeZone,
                        lineItems,
                        fetchLineItemsInProgress,
                        fetchLineItemsError,
                    } = fieldRenderProps;
                    onChangeFormValues(values);
                    const startTime =
                        values && values.bookingStartTime
                            ? values.bookingStartTime
                            : null;
                    const endTime =
                        values && values.bookingEndTime
                            ? values.bookingEndTime
                            : null;

                    const bookingStartLabel = intl.formatMessage({
                        id: 'BookingTimeForm.bookingStartTitle',
                    });
                    const bookingEndLabel = intl.formatMessage({
                        id: 'BookingTimeForm.bookingEndTitle',
                    });

                    const startDate = startTime
                        ? timestampToDate(startTime)
                        : null;
                    const endDate = endTime ? timestampToDate(endTime) : null;

                    const hours = startDate && endDate ? this.calculateQuantityFromHours(startDate, endDate) : 0
                    // This is the place to collect breakdown estimation data. See the
                    // EstimatedBreakdownMaybe component to change the calculations
                    // for customized payment processes.
                    const bookingData =
                        startDate && endDate
                            ? {
                                unitType,
                                startDate,
                                endDate,
                                timeZone,
                            }
                            : null;

                    const showEstimatedBreakdown =
                        bookingData &&
                        lineItems &&
                        !fetchLineItemsInProgress &&
                        !fetchLineItemsError;

                    const bookingInfoMaybe = showEstimatedBreakdown ? (
                        <div className={css.priceBreakdownContainer}>
                            <h3 className={css.priceBreakdownTitle}>
                                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                            </h3>
                            <EstimatedBreakdownMaybe
                                bookingData={bookingData}
                                lineItems={lineItems}
                                removeFee={this.state.removeFee}
                            />
                        </div>
                    ) : null;

                    const activityRequired = validators.required(
                        intl.formatMessage({
                            id: 'BookingTimeForm.activityRequired',
                        })
                    );

                    const selectedActivity = activities.find(
                        (_a) => _a.uuid === values?.activity
                    );

                    const maxNoOfGuest = selectedActivity?.maxNoOfGuest;
                    const specialAddOnTitles = specialAddOns ? specialAddOns.map(i => i.title) : []
                    const addOnsTitles = selectedActivity && selectedActivity.addOns ? selectedActivity?.addOns?.map(item => item.title) : []

                    const firstTitle = specialAddOnTitles.length > 0 ? specialAddOnTitles[0] : "";


                    if (firstTitle && !addOnsTitles.includes(firstTitle) && selectedActivity) {
                        const specialAddOnWithKey = [...selectedActivity.addOns, ...specialAddOns];
                        selectedActivity.addOns = specialAddOnWithKey;
                    };

                    let removeFee = false

                    const activityAddOnOptions =
                        selectedActivity?.addOns?.map((_add) => {
                            if (_add.removeFee) {
                                removeFee = true
                            }
                            const formattedPrice = formatMoney(
                                intl,
                                new Money(
                                    _add.price.amount * (removeFee ? 1.075 : 1.05),
                                    _add.price.currency
                                )
                            );

                            return {
                                key: _add.uuid,
                                label: `${_add.title} - ${formattedPrice} ${CHARGE_DISPLAY[_add.typeOfCharge]}`,
                                typeOfCharge: _add.typeOfCharge,
                                description: _add.description,
                                removeFee: _add.removeFee,
                            };
                        }) || [];

                    const enabledInstantBooking = selectedActivity?.feature_keys?.includes("allowInstantBooking");
                    //selectedActivity?.feature_key?.allowInstantBooking;

                    const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
                        <IconSpinner className={css.spinner} />
                    ) : null;

                    const bookingInfoErrorMaybe = fetchLineItemsError ? (
                        <span className={css.sideBarError}>
                            <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
                        </span>
                    ) : null;

                    const submitButtonClasses = classNames(
                        submitButtonWrapperClassName || css.submitButtonWrapper
                    );

                    const startDateInputProps = {
                        label: bookingStartLabel,
                        placeholderText: startDatePlaceholder,
                    };
                    const endDateInputProps = {
                        label: bookingEndLabel,
                        placeholderText: endDatePlaceholder,
                    };

                    const dateInputProps = {
                        startDateInputProps,
                        endDateInputProps,
                    };

                    const noOfGuestLabel = intl.formatMessage({
                        id: 'BookingTimeForm.noOfGuestLabel',
                    });

                    const noOfGuestPlaceholderMessage = intl.formatMessage({
                        id: 'BookingTimeForm.noOfGuestPlaceholder',
                    });
                    const noOfGuestRequiredMessage = intl.formatMessage({
                        id: 'BookingTimeForm.noOfGuestRequired',
                    });

                    const addOnQtyPlaceholderMessage = intl.formatMessage({
                        id: 'BookingTimeForm.addOnQtyPlaceholder',
                    });

                    const addOnsLabel = intl.formatMessage({
                        id: 'BookingTimeForm.addOnsLabel',
                    });

                    const activityLabel = intl.formatMessage({
                        id: 'BookingTimeForm.activityLabel',
                    });
                    const activityPlaceholderMessage = intl.formatMessage({
                        id: 'BookingTimeForm.activityPlaceholderMessage',
                    });

                    const maxGuestValidationMessage = intl.formatMessage({
                        id: 'BookingTimeForm.maxGuestValidationMessage',
                    });
                    const maxGuest = maxNoOfGuest;
                    const maxGuestValidation = validators.maxNo(
                        maxGuestValidationMessage + maxGuest,
                        maxGuest
                    );

                    const unselectedActivitiy = !values.activity;
                    const bookingTimeDisabled =
                        !values?.noOfGuest || unselectedActivitiy;

                    const invalidDates =
                        !values.bookingEndTime ||
                        !values.bookingStartTime ||
                        new Date(Number(values.bookingEndTime)) < new Date(Number(values.bookingStartTime) + (3600000 * minHour));

                    const requestToBookDisabled =
                        !values.noOfGuest ||
                        values.noOfGuest <= 0 ||
                        invalidDates;

                    //Tag Manager | Data Layer | When user click the book button will push the following data to the tag manager
                    //Get the Add Ons when the user checked
                    const va = values.addOns ? values.addOns : [];

                    // Start: Save add on item as HTML and use in Email template
                    const addOnItems = selectedActivity?.addOns
                        .map(
                            (_k) => {
                                // Find out any user checked add-ons(uuid) match the available add-ons
                                if (va[_k.uuid]) {
                                    //Check the addOn quantity is using insert number or not 
                                    const quantityMaybe = this.state.currentAddOn.find(_u => _u.uuid == _k.uuid)?.qty || 1;

                                    //If the addOn quantity dont have insert number will default using number of guest
                                    let quantity = 1
                                    //checkt the type of charge, there's different charges calculation on certain type
                                    if (_k.typeOfCharge == "perPiecePerHour") {
                                        quantity = quantityMaybe * Number(hours)
                                    } else if (_k.typeOfCharge == "perPerson") {
                                        quantity = values.noOfGuest
                                    } else if (_k.typeOfCharge == "perPiece") {
                                        quantity = quantityMaybe
                                    } else if (_k.typeOfCharge == "perPersonPerHour") {
                                        quantity = values.noOfGuest * Number(hours)
                                    } else if (_k.typeOfCharge == "perHour") {
                                        quantity = Number(hours)
                                    }

                                    //Show the price in correct format 5600 -> CHF 56.00
                                    const formattedPrice = formatMoney(
                                        intl,
                                        new Money(
                                            _k.price.amount * (removeFee ? 1.075 : 1.05),
                                            _k.price.currency
                                        )
                                    );

                                    //Calculate the total price of the addON
                                    const totalFormattedPrice = formatMoney(
                                        intl,
                                        new Money(
                                            _k.price.amount * (removeFee ? 1.075 : 1.05) * Number(quantity),
                                            _k.price.currency
                                        )
                                    );

                                    const typeOfChargeFormat =
                                        getIntlTypeOfChargeOptionLabel(
                                            _k.typeOfCharge,
                                            intl
                                        );

                                    return (`
                                            <table border="0" cellpadding="0" cellspacing="0" class="text_block block-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
                                                <tr>
                                                    <td class="pad" style="padding-left:35px;padding-right:10px;padding-top:10px;padding-bottom:5px;width:50%;">
                                                        <div style="font-family: sans-serif">
                                                            <div class="" style="font-size: 12px; mso-line-height-alt: 18px; color: #848484; line-height: 1.5; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">
                                                                <p style="margin: 0; font-size: 14px; mso-line-height-alt: 21px;">
                                                                    <span style="font-size:14px;">${_k.title} ${formattedPrice} x ${quantity} ${typeOfChargeFormat}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="pad" style="padding-left:35px;padding-right:10px;padding-top:10px;padding-bottom:5px;width:25%;">
                                                        <div style="font-family: sans-serif">
                                                            <div class="" style="font-size: 12px; mso-line-height-alt: 14.399999999999999px; color: #0c6132; line-height: 1.2; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;">
                                                                 <p style="margin: 0; font-size: 14px; mso-line-height-alt: 16.8px;">
                                                                    <span style="font-size:17px;">${totalFormattedPrice}</span></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        `);
                                }
                            }
                        )
                        // Match the user select addON item and listing available addON item
                        .filter((v) => v)
                        // Remove commas when few addON table join together in email template
                        .join("");

                    //End

                    //Here the process to get the add-ons title based on the user checked add-on(uuid):
                    // 1.Map the all available add-on of the activity that user selected
                    const addOnTitle = selectedActivity?.addOns.map((_k) => {

                        // 2. Find out any user checked add-ons(uuid) match the available add-ons
                        if (Object.keys(va).includes(_k.uuid)) {
                            // 3. Just return the matched object > add-ons title
                            return _k.title;
                        }
                    }
                        // 4. Only show the object which have the title
                    ).filter(v => v);

                    const neededParams = {
                        event: 'booking_details',
                        spaces: listing?.attributes?.title,
                        space_id: listing?.id.uuid,
                        activities: selectedActivity?.title,
                        no_of_guest: values?.noOfGuest,
                        add_ons: addOnTitle,
                        start_date: new Date(values?.bookingStartDate?.date).toLocaleDateString('en-GB'),
                        end_date: new Date(values?.bookingEndDate?.date).toLocaleDateString('en-GB'),
                        host_id: listing?.author?.id?.uuid,
                        host_name: listing?.author?.attributes?.profile?.displayName,
                    };

                    const submitTag = () => {

                        const estimatedTotalPrice = (lineItems) => {
                            const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
                                const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
                                return new Decimal(numericPrice).add(sum);
                            }, 0);

                            // All the lineItems should have same currency so we can use the first one to check that
                            // In case there are no lineItems we use currency from config.js as default
                            const currency =
                                lineItems[0] && lineItems[0].unitPrice
                                    ? lineItems[0].unitPrice.currency
                                    : config.currency;

                            return new Money(
                                convertUnitToSubUnit(
                                    numericTotalPrice.toNumber(),
                                    unitDivisor(currency)
                                ),
                                currency
                            );
                        };
                        const customerLineItems = lineItems.filter((item) =>
                            item.includeFor.includes('customer')
                        );

                        const payinTotal = estimatedTotalPrice(customerLineItems);
                        window.dataLayer.push({ ...neededParams, price: payinTotal.amount / 100, currency: payinTotal.currency });

                        window.localStorage.setItem('activities', selectedActivity?.title);
                        window.localStorage.setItem('addOnTitle', addOnItems);
                    };

                    return (
                        <>
                            <Form
                                onSubmit={handleSubmit}
                                className={classes}
                                enforcePagePreloadFor="CheckoutPage">
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={(values) => {
                                        this.handleOnChange(values);
                                    }}
                                />
                                <FieldSelect
                                    id={'activity'}
                                    name="activity"
                                    className={css.activity}
                                    label={activityLabel}
                                    validate={activityRequired}
                                    onClick={() => {
                                        values.bookingEndDate = null;
                                        values.bookingStartDate = null;
                                    }}>
                                    <option disabled value="">
                                        {activityPlaceholderMessage}
                                    </option>
                                    {activities.map((_act) => {
                                        const formattedPrice = formatMoney(
                                            intl,
                                            new Money(
                                                _act.price.amount,
                                                _act.price.currency
                                            )
                                        );
                                        return (
                                            <option
                                                value={_act.uuid}
                                                label={_act.title}>
                                                {_act.title} - {formattedPrice}
                                            </option>
                                        );
                                    })}
                                </FieldSelect>

                                <FieldTextInput
                                    className={css.noOfGuest}
                                    id="noOfGuest"
                                    name="noOfGuest"
                                    type="number"
                                    max={maxNoOfGuest}
                                    label={noOfGuestLabel}
                                    placeholder={noOfGuestPlaceholderMessage}
                                    validate={validators.composeValidators(
                                        maxGuestValidation,
                                        validators.required(
                                            noOfGuestRequiredMessage
                                        )
                                    )}
                                    disabled={unselectedActivitiy}
                                />
                                {maxNoOfGuest && (
                                    <p className={css.maxNoOfGuestInfo}>
                                        <FormattedMessage
                                            id={'BookingTimeForm.maxNoOfGuestInfo'}
                                            values={{ maxNoOfGuest }}
                                        />
                                    </p>
                                )}

                                {/* {specialAddOnTitles  ?  <div className={css.specialAddOnTitles}>{specialAddOnTitles} has been added!</div>: null}  */}

                                {values?.noOfGuest ? (
                                    <div className={css.addOns} >
                                        <label for="addOns">{addOnsLabel}</label>
                                        {activityAddOnOptions.map((_op) => {
                                            const _requiresPiece =
                                                _op.typeOfCharge ===
                                                TYPE_OF_CHARGE.PER_PIECE_PER_HOUR ||
                                                _op.typeOfCharge ===
                                                TYPE_OF_CHARGE.PER_PIECE;
                                            const _isChecked = Object.keys(
                                                values?.addOns || {}
                                            )?.find(
                                                (_k) =>
                                                    _k === _op.key &&
                                                    values?.addOns[_k]
                                            );

                                            const tst =
                                                <div className={css.infoIconWrapper} >
                                                    <div className={css.addOnLabel}>{_op.label}</div>{_op.description ? <div className={css.hovertext} data-hover={_op.description} onClick={(e) => e.preventDefault()} ><IconInfo /></div>
                                                        : null}</div>
                                            return (
                                                <div >
                                                    <div className={css.addOn}>
                                                        <FieldCheckbox
                                                            id={`addOns.${_op.key}`}
                                                            name={`addOns.${_op.key}`}
                                                            label={tst}
                                                        />
                                                    </div>
                                                    {_requiresPiece &&
                                                        _isChecked && (
                                                            <FieldTextInput
                                                                className={
                                                                    css.addOnQty
                                                                }
                                                                id={`addOns.${_op.key}.qty`}
                                                                name={`addOns.${_op.key}.qty`}
                                                                type="number"
                                                                placeholder={
                                                                    addOnQtyPlaceholderMessage
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}

                                {monthlyTimeSlots && timeZone ? (
                                    <FieldDateAndTimeInput
                                        {...dateInputProps}
                                        className={css.bookingDates}
                                        listingId={listingId}
                                        bookingStartLabel={bookingStartLabel}
                                        onFetchTimeSlots={onFetchTimeSlots}
                                        monthlyTimeSlots={monthlyTimeSlots}
                                        values={values}
                                        intl={intl}
                                        form={form}
                                        pristine={pristine}
                                        timeZone={timeZone}
                                        minHour={minHour}
                                        dateInputDisabled={bookingTimeDisabled}
                                        invalidDates={invalidDates}
                                    />
                                ) : null}

                                {/* {selectedActivityAddOnDetailsMaybe} */}
                                {bookingInfoMaybe}
                                {loadingSpinnerMaybe}
                                {bookingInfoErrorMaybe}
                                {enabledInstantBooking && <p className={css.smallPrint}>
                                    <FormattedMessage
                                        id={
                                            isOwnListing
                                                ? 'BookingTimeForm.ownListing'
                                                : 'BookingTimeForm.youWontBeChargedInfo'
                                        }
                                    />
                                </p>}

                                {/* <div className={submitButtonClasses}> */}
                                <div className={css.buttonIndex}  >
                                    {enabledInstantBooking ? (
                                        <div>
                                            <PrimaryButton
                                                disabled={requestToBookDisabled}
                                                type="submit"
                                                onClick={() => {
                                                    values.isInstantBooking = true;
                                                    submitTag();
                                                }}>
                                                <FormattedMessage id="BookingTimeForm.instantBooking" />
                                            </PrimaryButton>
                                        </div>
                                    ) :
                                        (
                                            isListingPagePanel ? null : <div className={css.requestToBookButtonContainer}>
                                                <PrimaryButton
                                                    className={css.buttonIndex}
                                                    type="submit"
                                                    disabled={requestToBookDisabled}
                                                    onClick={() => {
                                                        submitTag();
                                                    }}>
                                                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                                                </PrimaryButton>
                                            </div>
                                        )}
                                </div>
                                {onContactUser && (
                                    <>
                                        <PrimaryButton
                                            type="button"
                                            className={css.contactLink}
                                            onClick={() => onContactUser(values)}
                                            disabled={!values.activity || !values.noOfGuest || (values.noOfGuest > Number(maxNoOfGuest))}
                                            enforcePagePreloadFor="SignupPage"
                                        >
                                            {/* <IconChat /> */}
                                            {isListingPagePanel ? "Enquire" :
                                                <FormattedMessage id="BookingTimeForm.chatWithHost" />
                                            }
                                        </PrimaryButton>
                                        <p className={css.chatText}>
                                            No credit card needed. Use this feature to check availability, ask any questions, or to get an individual quote from your host.
                                        </p>
                                    </>
                                )}
                            </Form>
                        </>
                    );
                }}
            />
        );
    }
}

BookingTimeFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    submitButtonWrapperClassName: null,
    price: null,
    isOwnListing: false,
    listingId: null,
    startDatePlaceholder: null,
    endDatePlaceholder: null,
    monthlyTimeSlots: null,
    lineItems: null,
    fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    submitButtonWrapperClassName: string,

    unitType: propTypes.bookingUnitType.isRequired,
    price: propTypes.money,
    isOwnListing: bool,
    listingId: propTypes.uuid,
    monthlyTimeSlots: object,
    onFetchTimeSlots: func.isRequired,

    onFetchTransactionLineItems: func.isRequired,
    lineItems: array,
    fetchLineItemsInProgress: bool.isRequired,
    fetchLineItemsError: propTypes.error,

    // from injectIntl
    intl: intlShape.isRequired,

    // for tests
    startDatePlaceholder: string,
    endDatePlaceholder: string,
};
const mapDispatchToProps = (dispatch) => ({
    onUpdateProfile: (data) => dispatch(updateProfile(data)),
});
const mapStateToProps = (state) => {
    return {}
}

const BookingTimeForm = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
